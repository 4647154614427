
import { defineComponent } from 'vue'

export default defineComponent({
  name: '',
  setup () {
    const imgUrl = [
      [
        require('../../assets/images/TECNO.webp'),
        require('../../assets/images/Infinix.webp'),
        require('../../assets/images/itel.webp'),
        require('../../assets/images/MI.webp'),
        require('../../assets/images/SHAREit.webp'),
        require('../../assets/images/谷歌云.webp'),
      ],
      [
        require('../../assets/images/首云.webp'),
        require('../../assets/images/华为.webp'),
        require('../../assets/images/Meta.webp'),
        require('../../assets/images/Payermax.webp'),
        require('../../assets/images/Dana.webp'),
        require('../../assets/images/Bingoplus.webp'),
      ],
      [
        require('../../assets/images/Shopee.webp'),
        require('../../assets/images/Lazada.webp'),
        require('../../assets/images/SHEIN.webp'),
        require('../../assets/images/Garena.webp'),
        require('../../assets/images/Kredivo.webp'),
        require('../../assets/images/okopp.webp'),
      ],
      [
        require('../../assets/images/itel3.webp'),
        require('../../assets/images/itel2.webp'),
        require('../../assets/images/uizalabs.webp'),
        require('../../assets/images/binance.webp'),
        require('../../assets/images/bybit.webp'),
        require('../../assets/images/omne.webp'),
      ],
    ]
    return {
      imgUrl
     }
  }
})
