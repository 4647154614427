
import { defineComponent, ref, onMounted} from 'vue'
import moudle1 from '@/components/home/moudle1.vue'
import moudle2 from '@/components/home/moudle2.vue'
import moudle3 from '@/components/home/moudle3.vue'
import moudle4 from '@/components/home/moudle4.vue'
import moudle4_1 from '@/components/home/moudle4_1.vue'
import moudle5 from '@/components/home/moudle5.vue'
import moudle6 from '@/components/home/moudle6.vue'
export default defineComponent({
  name: '',
  components: {
    moudle1,
    moudle2,
    moudle3,
    moudle4,
    moudle4_1,
    moudle5,
    moudle6,
    
  },
  setup () {
    const about = ref();
    const checkTitle = ref(0)

    const moudleClickHandle = (type:string) => {
      console.log(type)
      switch(type) {
        case 'about':
          checkTitle.value = 1
          break;
        case 'soltuions':
          changeDomMoudlu(2)
          break;
        case 'advertisers':
          changeDomMoudlu(3)
          break;
        case 'publisher':
          changeDomMoudlu(4)
          break;
        case 'global':
          changeDomMoudlu(5)
          break;
        case 'allies':
          changeDomMoudlu(6) 
          break;
      }
    }

    const changeDomMoudlu = (dom:number) => {
      checkTitle.value = dom
      window.scrollTo(0, about.value.children[1].children[dom].getBoundingClientRect().top - 100)
          about.value.children[1].children[dom].animate(
            [{
              opacity: 0
            },
            {
              opacity: 1
            }],
              {
                duration: 500,
                easing: 'ease',
                delay: 0,
                mode: 'backwords'
              }
          ) 
    }
    
    onMounted(() => {
console.log(about.value.children[1].children[2].getBoundingClientRect().top)
    })
    return {
      about,
      checkTitle,
      moudleClickHandle
    }
  }
})
